<template>
  <wasteproduction-edit
    :organization-waste="organizationWaste"
  ></wasteproduction-edit>
</template>

<script>
import WasteproductionEdit from "@/components/ecologist/wasteproduction/wasteproductionEdit";
import { mapGetters } from "vuex";
export default {
  name: "wasteproductionEditView",
  components: { WasteproductionEdit },

  computed: {
    ...mapGetters({
      organizationWaste: "GETORGANIZATIONWASTE",
    }),
  },

  async mounted() {},
};
</script>

<style scoped></style>
