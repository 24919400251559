import { render, staticRenderFns } from "./wasteproductionEditView.vue?vue&type=template&id=71ecdb3a&scoped=true&"
import script from "./wasteproductionEditView.vue?vue&type=script&lang=js&"
export * from "./wasteproductionEditView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ecdb3a",
  null
  
)

export default component.exports